<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Add Project
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6 lg8 class="pa-3">
            <p class="mb-1 grey--text"><b>Project</b></p>
            <v-text-field
              label="Project Title"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg4 class="pa-3">
            <p class="mb-1 grey--text"><b>Location</b></p>
            <v-text-field
              label="Project location"
              single-line
              outlined
              v-model="location"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 lg6 class="pa-3">
            <p class="mb-1 grey--text"><b>Description</b></p>
            <v-textarea
              label="A brief description"
              single-line
              outlined
              v-model="description"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 md12 lg6 class="pa-3">
            <p class="mb-1 grey--text"><b>Client</b></p>
            <v-textarea
              label="Client information"
              single-line
              outlined
              v-model="customer"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-btn x-large class="primary black--text" @click="createProject"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      name: "",
      location: "",
      description: "",
      customer: "",
    };
  },
  methods: {
    async createProject() {
      let url = BASE_URL + "/project";
      let payload = {
        name: this.name,
        location: this.location,
        customer: this.customer,
        description: this.description,
      };
      if (this.name != "") {
        let { data } = await Axios.post(url, payload);
        this.$router.push("/projects");
        this.$toastr.s(
          "New project has been created successfully!",
          "Project Added"
        );
      } else {
        this.$toastr.e("You cannot adda project without a title!", "Error");
      }
    },
  },
};
</script>